import React, { useState } from 'react';
import styled from 'styled-components';
import { useStoreState } from 'easy-peasy';
import { Link, useLocation } from 'react-router-dom';
import MenuIcon from '../../assets/images/menu-icon.svg';
import CartIcon from '../../assets/images/cart-icon.svg';
import InstagramIcon from '../../assets/images/instagram-icon.svg';
import FacebookIcon from '../../assets/images/facebook-icon.svg';
import { colors, breakpoints, globalUrls, pages } from '../../config/constants';

const Menu = () => {
  const shoppingCart = useStoreState((state) => state.shoppingCart.shoppingCart);
  const [showMenu, getShowMenu] = useState(false);
  const location = useLocation();

  const isCurrentUrl = (url) => {
    const isCurrent = location.pathname === url;
    return isCurrent.toString();
  };

  const getAllCartItems = () => {
    let itemNumber = 0;
    shoppingCart.forEach((item) => {
      itemNumber += item.quantity;
    });
    return itemNumber;
  };

  return (
    <>
      <MobileMenu>
        <MenuSection onClick={() => getShowMenu(!showMenu)}>
          <img src={MenuIcon} alt="icono menu" />
          <MenuText>Menú</MenuText>
        </MenuSection>
        <MenuSection>
          <CartLink to="/carrito-de-compras" disabled={getAllCartItems() === 0}>
            {getAllCartItems() > 0 && <ItemsNumber>{getAllCartItems()}</ItemsNumber>}
            <img src={CartIcon} alt="icono carrito" />
          </CartLink>
        </MenuSection>
        {showMenu && (
          <MenuLinks>
            {pages.map((page, index) => (
              <Link key={`page-${index + 1}`} to={`/${page.url}`}>
                <MenuLink>{page.name}</MenuLink>
              </Link>
            ))}
            <a href={globalUrls.instagramUrl} target="_blank" rel="noreferrer">
              <MenuLink>INSTAGRAM</MenuLink>
            </a>
            <a href={globalUrls.facebookUrl} target="_blank" rel="noreferrer">
              <MenuLink>FACEBOOK</MenuLink>
            </a>
          </MenuLinks>
        )}
      </MobileMenu>
      <DesktopMenu>
        {pages.map((page, index) => (
          <StyledLink
            key={`page-${index + 1}`}
            active={isCurrentUrl(`/${page.url}`)}
            to={`/${page.url}`}
          >
            {page.name}
          </StyledLink>
        ))}
        <StyledAnchor href={globalUrls.instagramUrl} target="_blank">
          <img src={InstagramIcon} alt="icono instagram" />
        </StyledAnchor>
        <StyledAnchor href={globalUrls.facebookUrl} target="_blank">
          <img src={FacebookIcon} alt="icono instagram" />
        </StyledAnchor>
      </DesktopMenu>
    </>
  );
};

const MobileMenu = styled.div`
  font-family: 'RobotoCondensedRegular';
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  height: 50px;
  background-color: ${colors.white};
  text-align: left;
  color: ${colors.mainBlue};
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  position: relative;

  @media (min-width: ${breakpoints.lg}px) {
    display: none;
  }
`;

const MenuText = styled.div`
  margin-left: 10px;
  line-height: 1.5;
`;

const MenuSection = styled.div`
  display: flex;
  cursor: pointer;
`;

const CartLink = styled(Link)`
  display: flex;
  pointer-events: none;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
`;

const ItemsNumber = styled.div`
  width: 20px;
  height: 20px;
  background-color: ${colors.mainBlue};
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'RobotoBold';
  font-size: 12px;
  color: ${colors.mainOrange};
  margin-right: 9px;
`;

const MenuLinks = styled.nav`
  width: 200px;
  background-color: ${colors.mainBlue};
  padding: 19px 25px 35px;
  position: absolute;
  left: 0;
  top: 100%;
  z-index: 9999;

  a {
    text-decoration: none;

    &:last-child {
      div {
        border-bottom: 0;
      }
    }
  }
`;

const MenuLink = styled.div`
  color: ${colors.white};
  font-weight: normal;
  border-bottom: 1px solid white;
  padding: 6px 0;
`;

const DesktopMenu = styled(MobileMenu)`
  display: none;
  justify-content: flex-start;
  font-size: 12px;

  @media (min-width: ${breakpoints.lg}px) {
    display: flex;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  border-right: 1px solid ${colors.mainBlue};
  padding-right: 8px;
  margin-right: 8px;
  color: ${colors.mainBlue};
  text-decoration: ${(props) => (props.active === 'true' ? 'underline' : 'none')};

  &:hover {
    text-decoration: underline;
  }
`;

const StyledAnchor = styled.a`
  text-decoration: none;
  margin-right: 8px;
  color: ${colors.mainBlue};
`;

export default Menu;
