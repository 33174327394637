export const breakpoints = {
  sm: 480,
  md: 768,
  lg: 1200,
};

export const colors = {
  white: '#fff',
  mainBlue: '#3e667a',
  mainOrange: '#ffb37a',
  textBackground: '#9fb2bc',
  purchaseBackground: '#dee2e5',
  red: '#eb697f',
};

export const globalUrls = {
  instagramUrl: 'https://www.instagram.com/eufoniaediciones/',
  facebookUrl: 'https://www.facebook.com/eufoniaediciones/',
};

export const pages = [
  {
    name: 'Libros',
    url: '',
  },
  {
    name: 'Sobre nosotros',
    url: 'sobre-nosotros',
  },
  {
    name: 'Autores',
    url: 'autores',
  },
  {
    name: 'Ilustradores',
    url: 'ilustradores',
  },
  {
    name: 'Galería de arte',
    url: 'galeria-de-arte',
  },
  {
    name: 'Puntos de distribución',
    url: 'puntos-de-distribucion',
  },
  {
    name: 'Contáctanos',
    url: 'contactanos',
  },
  // {
  //   name: 'Enjambre',
  //   url: 'enjambre',
  // },
];

export const provinces = [
  {
    id: 1,
    name: 'Pichincha',
  },
  {
    id: 2,
    name: 'Guayas',
  },
];

export const cities = [
  {
    id: 1,
    name: 'Quito',
    provinceId: 1,
    shippingPrice: 2,
  },
  {
    id: 2,
    name: 'Otra',
    provinceId: 1,
    shippingPrice: 7,
  },
  {
    id: 3,
    name: 'Guayaquil',
    provinceId: 2,
    shippingPrice: 5,
  },
];

export const paymentMethods = [
  {
    id: 1,
    name: 'Transferencia/depósito bancario',
  },
];

export const shippingMethods = [
  {
    id: 1,
    name: 'Quito y los valles (Ecuador)',
    shippingPrice: 3,
  },
  {
    id: 2,
    name: 'Resto del país (Ecuador)',
    shippingPrice: 5,
  },
  {
    id: 3,
    name: 'No aplica (libro digital)',
    shippingPrice: 0,
  },
];
