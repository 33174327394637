import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import InstagramIcon from '../../assets/images/instagram-icon.svg';
import FacebookIcon from '../../assets/images/facebook-icon.svg';
import { colors, breakpoints, globalUrls, pages } from '../../config/constants';

const Footer = ({ footerColor }) => {
  const location = useLocation();

  const isCurrentUrl = (url) => {
    const isCurrent = location.pathname === url;
    return isCurrent.toString();
  };

  const handleToTop = () => {
    // eslint-disable-next-line no-undef
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <StyledFooter footerColor={footerColor}>
      {location.pathname !== '/sobre-nosotros' &&
        location.pathname !== '/contactanos' &&
        location.pathname !== '/carrito-de-compras' && (
          <ToTop onClick={() => handleToTop()}>Volver hacia arriba</ToTop>
        )}
      <PagesMenu>
        {pages.map((page, index) => (
          <StyledLink key={`page-${index + 1}`} active={isCurrentUrl(`/${page.url}`)} to={page.url}>
            {page.name}
          </StyledLink>
        ))}
        <StyledAnchor href={globalUrls.instagramUrl} target="_blank">
          <img src={InstagramIcon} alt="icono instagram" />
        </StyledAnchor>
        <StyledAnchor href={globalUrls.facebookUrl} target="_blank">
          <img src={FacebookIcon} alt="icono instagram" />
        </StyledAnchor>
      </PagesMenu>
      <FooterContent>© Eufonía ediciones</FooterContent>
    </StyledFooter>
  );
};

const StyledFooter = styled.footer`
  font-family: 'RobotoSlabRegular';
  font-size: 11px;
  font-weight: bold;

  @media (min-width: ${breakpoints.md}px) {
    background-color: ${colors.white};
    flex: ${(props) => (props.footerColor ? 1 : 'none')};
  }

  @media (min-width: ${breakpoints.lg}px) {
    background-color: ${(props) => (props.footerColor ? props.footerColor : colors.white)};
  }
`;

const ToTop = styled.button`
  font-family: 'RobotoSlabRegular';
  font-size: 11px;
  font-weight: bold;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.mainBlue};
  color: ${colors.white};
  cursor: pointer;
  border: 0;
  width: 100%;
  font-family: inherit;
  border-bottom: 2px solid ${colors.white};
`;

const PagesMenu = styled.div`
  display: none;
  justify-content: center;
  font-size: 12px;
  font-family: 'RobotoSlabLight';
  align-items: center;
  padding: 0 20px;
  background-color: ${colors.mainOrange};
  height: 30px;
  color: ${colors.mainBlue};

  @media (min-width: ${breakpoints.lg}px) {
    display: flex;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  border-right: 1px solid ${colors.mainBlue};
  padding-right: 8px;
  margin-right: 8px;
  color: ${colors.mainBlue};
  text-decoration: ${(props) => (props.active === 'true' ? 'underline' : 'none')};

  &:hover {
    text-decoration: underline;
  }
`;

const StyledAnchor = styled.a`
  text-decoration: none;
  margin-right: 8px;
  color: ${colors.mainBlue};
`;

const FooterContent = styled.div`
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.mainOrange};
  color: ${colors.mainBlue};

  @media (min-width: ${breakpoints.lg}px) {
    background-color: ${colors.white};
  }
`;

Footer.propTypes = {
  footerColor: PropTypes.string,
};

Footer.defaultProps = {
  footerColor: '',
};

export default Footer;
