import React from 'react';
import styled from 'styled-components';
import { useStoreState } from 'easy-peasy';
import { Link } from 'react-router-dom';
import Menu from '../Menu';
import LogoIcon from '../../assets/images/logo.svg';
import TitleImage from '../../assets/images/title.png';
import WhiteCartIcon from '../../assets/images/white-cart-icon.svg';
import { colors, breakpoints } from '../../config/constants';

const Header = () => {
  const shoppingCart = useStoreState((state) => state.shoppingCart.shoppingCart);

  const getAllCartItems = () => {
    let itemNumber = 0;
    shoppingCart.forEach((item) => {
      itemNumber += item.quantity;
    });
    return itemNumber;
  };

  return (
    <>
      <StyledHeader>
        <Link to="/">
          <Logo>
            <img src={LogoIcon} alt="logo eufonia" />
          </Logo>
        </Link>
        <Title>
          <Link to="/">
            <img src={TitleImage} alt="eufonia ediciones" />
          </Link>
          <ShoppingCart>
            <StyledLink to="/carrito-de-compras">
              {getAllCartItems() > 0 && <CartItems>{getAllCartItems()}</CartItems>}
              <img src={WhiteCartIcon} alt="icono carrito" />
            </StyledLink>
          </ShoppingCart>
        </Title>
      </StyledHeader>
      <Menu />
    </>
  );
};

const StyledHeader = styled.header`
  display: flex;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const Logo = styled.div`
  background-color: ${colors.mainBlue};
  width: 99px;
  height: 100px;
  border-right: 2px solid ${colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Title = styled.div`
  background-color: ${colors.mainOrange};
  display: flex;
  flex: 1;
  align-items: center;
  padding-left: 28px;
  position: relative;

  img {
    width: 120px;
  }
`;

const ShoppingCart = styled.div`
  background-color: ${colors.mainBlue};
  width: 50px;
  height: 50px;
  border-left: 2px solid ${colors.white};
  border-bottom: 2px solid ${colors.white};
  display: none;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;

  img {
    width: 23px;
    height: 23px;
  }

  @media (min-width: ${breakpoints.lg}px) {
    display: flex;
  }
`;

const CartItems = styled.div`
  color: ${colors.mainOrange};
  font-family: 'RobotoBold';
  font-size: 10px;
  text-align: center;
`;

export default Header;
