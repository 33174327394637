import { gql } from '@apollo/client';

const GET_BOOKS = gql`
  query GetBooks {
    bookCollection(order: [publicationDate_DESC]) {
      items {
        sys {
          id
        }
        title
        author
        description
        price
        category {
          name
        }
        digital {
          sys {
            id
          }
          price
        }
        characteristicsCollection(limit: 10) {
          items {
            title {
              title
            }
            value {
              value
            }
          }
        }
        imagesCollection(limit: 10) {
          items {
            title
            url
          }
        }
      }
    }
  }
`;

export default GET_BOOKS;
