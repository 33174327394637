import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import Layout from '../../components/Layout';
import PersonInfo from '../../components/PersonInfo';
import GET_ILLUSTRATORS from './queries';

const parseIllustrators = (illustrators) => {
  const parsedData = illustrators.illustratorCollection.items.map((illustrator) => {
    const parsedIllustrator = {
      id: illustrator.sys.id,
      name: illustrator.name,
      photoUrl: illustrator.photo.url,
      description: illustrator.description.json,
      link: illustrator.link,
      linkText: illustrator.linkText,
    };
    return parsedIllustrator;
  });
  return parsedData;
};

const IllustratorsPage = () => {
  const { data: illustrators } = useQuery(GET_ILLUSTRATORS);

  useEffect(() => {
    document.title = 'Eufonía - Ilustradores'; // eslint-disable-line
  }, []);

  return (
    <Layout>
      {illustrators &&
        parseIllustrators(illustrators).map((illustrator) => (
          <PersonInfo key={illustrator.id} author={illustrator} isIllustrator />
        ))}
    </Layout>
  );
};

export default IllustratorsPage;
