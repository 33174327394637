import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Header from '../Header';
import Footer from '../Footer';
import { colors, breakpoints } from '../../config/constants';

const Layout = ({ children, special, footerColor }) => (
  <LayoutWrapper>
    <Header />
    <MainSeparator />
    <LayoutMain special={special}>{children}</LayoutMain>
    <Footer footerColor={footerColor} />
  </LayoutWrapper>
);

const LayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const MainSeparator = styled.div`
  width: 100%;
  height: 18px;
  background-color: ${colors.mainOrange};
`;

const LayoutMain = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1;

  @media (min-width: ${breakpoints.md}px) {
    flex: ${(props) => (props.special ? 'none' : 1)};
  }
`;

Layout.propTypes = {
  children: PropTypes.node,
  special: PropTypes.bool,
  footerColor: PropTypes.string,
};

Layout.defaultProps = {
  children: null,
  special: false,
  footerColor: '',
};

export default Layout;
