import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import BlueTailIcon from '../../assets/images/blue-tail-icon.svg';
import { colors, breakpoints } from '../../config/constants';

const Button = ({ text, onClick, disabled, className }) => (
  <CustomButton className={className} onClick={(e) => onClick(e)} disabled={disabled}>
    <ButtonText>{text}</ButtonText>
    <ButtonTail src={BlueTailIcon} alt="" />
  </CustomButton>
);

const CustomButton = styled.button`
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  display: flex;
  margin-top: 2px;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
`;

const ButtonText = styled.div`
  height: 26px;
  padding: 0 10px;
  background-color: ${colors.mainBlue};
  color: ${colors.white};
  display: flex;
  align-items: center;
  font-family: 'RobotoSlabBold';
  font-size: 12px;

  @media (min-width: ${breakpoints.lg}px) {
    font-size: 14px;
  }
`;

const ButtonTail = styled.img`
  height: 26px;
  margin-left: -1px;
`;

Button.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

Button.defaultProps = {
  text: '',
  onClick: () => {},
  disabled: false,
  className: '',
};

export default Button;
