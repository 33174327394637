import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useStoreState, useStoreActions } from 'easy-peasy';
import Characteristics from '../Characteristics';
import BlueDiamondIcon from '../../assets/images/light-blue-diamond-icon.svg';
import WhiteDiamondIcon from '../../assets/images/white-diamond-icon.svg';
import PlusIcon from '../../assets/images/plus-icon.svg';
import MinusIcon from '../../assets/images/minus-icon.svg';
import WhiteCartIcon from '../../assets/images/white-cart-icon.svg';
import { colors, breakpoints } from '../../config/constants';

const GalleryItem = ({ galleryItem, onImageClick }) => {
  const shoppingCart = useStoreState((state) => state.shoppingCart.shoppingCart);
  const addToCart = useStoreActions((actions) => actions.shoppingCart.addToCart);
  const removeFromCart = useStoreActions((actions) => actions.shoppingCart.removeFromCart);

  const getProduct = (id) => shoppingCart.find((shoppingCartItem) => shoppingCartItem.id === id);

  const handleAddProduct = (item) => {
    addToCart({
      id: item.sys.id,
      quantity: 1,
      price: item.price,
      title: item.name,
      author: galleryItem.author,
      isDigital: 'product',
    });
  };

  const handleRemoveProduct = (item) => {
    removeFromCart({
      id: item.sys.id,
      quantity: 1,
      price: item.price,
      title: item.name,
      author: galleryItem.author,
      isDigital: 'product',
    });
  };

  return (
    <ContentWrapper special={galleryItem.specialImage}>
      <ImageButton special={galleryItem.specialImage} onClick={() => onImageClick(galleryItem)}>
        <Image src={galleryItem.imageUrl} alt="" />
      </ImageButton>
      <InfoWrapper special={galleryItem.specialImage}>
        <Title special={galleryItem.specialImage}>{galleryItem.title}</Title>
        <StyledCharacteristics characteristics={galleryItem.characteristics} />
        <Description>{galleryItem.technique}</Description>
        <ProductsWrapper>
          {galleryItem.products.map((product) => (
            <ProductWrapper key={product.sys.id} special={galleryItem.specialImage}>
              <DimensionsWrapper>
                <Cart src={WhiteCartIcon} alt="icono carrito" />
                <Dimensions>{product.dimension}</Dimensions>
                <Price>{`$ ${product.price.toFixed(2)} USD (${
                  product.hasFrame ? 'con marco' : 'sin marco'
                })`}</Price>
              </DimensionsWrapper>
              <QuantityWrapper>
                <BlueDiamond onClick={() => handleAddProduct(product)}>
                  <img src={PlusIcon} alt="" />
                </BlueDiamond>
                <ItemsNumber>{getProduct(product.sys.id)?.quantity || 0}</ItemsNumber>
                <BlueDiamond onClick={() => handleRemoveProduct(product)}>
                  <img src={MinusIcon} alt="" />
                </BlueDiamond>
              </QuantityWrapper>
            </ProductWrapper>
          ))}
        </ProductsWrapper>
      </InfoWrapper>
    </ContentWrapper>
  );
};

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;

  &:first-child {
    border-top: 2px solid ${colors.white};
  }

  @media (min-width: ${breakpoints.lg}px) {
    margin-bottom: 0;
    flex-direction: ${(props) => (props.special ? 'column' : 'row')};
  }
`;

const ImageButton = styled.button`
  display: flex;
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  border-bottom: 2px solid ${colors.white};

  @media (min-width: ${breakpoints.lg}px) {
    border-right: 2px solid ${colors.white};
    width: ${(props) => (props.special ? '1168px' : '400px')};
  }
`;

const Image = styled.img`
  width: 100%;
`;

const InfoWrapper = styled.div`
  @media (min-width: ${breakpoints.lg}px) {
    display: flex;
    flex-direction: column;
    justify-content: end;
    width: ${(props) => (props.special ? '1168px' : '768px')};
  }
`;

const Title = styled.div`
  background-color: ${colors.red};
  color: ${colors.white};
  font-family: 'RobotoBold';
  font-size: 24px;
  font-style: normal;
  text-transform: uppercase;
  padding: 7px 48px 4px 48px;
  border-bottom: 2px solid ${colors.white};

  @media (min-width: ${breakpoints.lg}px) {
    background-color: ${colors.white};
    color: ${colors.mainBlue};
    font-size: 36px;
    margin-left: 50px;
    margin-bottom: 12px;
    padding: 0;
    border-bottom: none;
    margin-top: ${(props) => (props.special ? '20px' : '0')};
  }
`;

const StyledCharacteristics = styled(Characteristics)`
  display: none;

  @media (min-width: ${breakpoints.lg}px) {
    display: block;
    margin-left: 50px;
    margin-bottom: 22px;
  }
`;

const Description = styled.div`
  background-color: ${colors.mainOrange};
  padding: 7px 48px;
  color: ${colors.mainBlue};
  font-family: 'RobotoSlabBold';
  font-size: 12px;
  line-height: 14px;
  border-bottom: 2px solid ${colors.white};
`;

const ProductsWrapper = styled.div`
  @media (min-width: ${breakpoints.lg}px) {
    display: flex;
    flex-wrap: wrap;
  }
`;

const ProductWrapper = styled.div`
  display: flex;
  background-color: ${colors.mainBlue};
  border-bottom: 2px solid ${colors.white};
  justify-content: space-between;

  @media (min-width: ${breakpoints.lg}px) {
    border-right: 2px solid ${colors.white};
    box-sizing: border-box;
    width: ${(props) => (props.special ? '33.33%' : '50%')};
  }
`;

const DimensionsWrapper = styled.div`
  padding: 2px 0 5px 48px;
`;

const Cart = styled.img`
  width: 15px;
  margin-left: -22px;
  margin-right: 7px;
`;

const Dimensions = styled.span`
  color: ${colors.white};
  font-family: 'RobotoSlabBold';
  font-size: 12px;
  margin-right: 3px;
`;

const Price = styled.span`
  color: ${colors.white};
  font-family: 'RobotoSlabLight';
  font-size: 12px;
`;

const mobileHeight = '26px';
const desktopHeight = '30px';

const QuantityWrapper = styled.div`
  display: flex;
`;

const BlueDiamond = styled.button`
  font-family: 'RobotoBold';
  background-image: url(${BlueDiamondIcon});
  background-size: cover;
  width: ${mobileHeight};
  height: ${mobileHeight};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.white};
  font-size: 12px;
  cursor: pointer;
  border: 0;
  background-color: transparent;
  padding: 0;
  flex-shrink: 0;

  @media (min-width: ${breakpoints.lg}px) {
    width: ${desktopHeight};
    height: ${desktopHeight};
  }
`;

const ItemsNumber = styled.div`
  font-family: 'RobotoBold';
  background-image: url(${WhiteDiamondIcon});
  background-size: cover;
  width: ${mobileHeight};
  height: ${mobileHeight};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.mainBlue};
  font-size: 12px;
  flex-shrink: 0;

  @media (min-width: ${breakpoints.lg}px) {
    width: ${desktopHeight};
    height: ${desktopHeight};
  }
`;

GalleryItem.propTypes = {
  galleryItem: PropTypes.shape({
    imageUrl: PropTypes.string,
    title: PropTypes.string,
    technique: PropTypes.string,
    author: PropTypes.string,
    specialImage: PropTypes.bool,
    characteristics: PropTypes.arrayOf(PropTypes.shape({})),
    products: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  onImageClick: PropTypes.func,
};

GalleryItem.defaultProps = {
  galleryItem: {},
  onImageClick: () => {},
};

export default GalleryItem;
