import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Redirect } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Layout from '../../components/Layout';
import Button from '../../components/Button';
import ArrowIcon from '../../assets/images/arrow-icon.svg';
import PlusIcon from '../../assets/images/plus-icon.svg';
import MinusIcon from '../../assets/images/minus-icon.svg';
import SuccessfulPurchase from '../../assets/images/successful-purchase.jpg';
import ProcessingPurchase from '../../assets/images/processing-purchase.png';
import { colors, breakpoints, shippingMethods, paymentMethods } from '../../config/constants';
import { createPurchase } from '../../config/contentfulAPI';

const ShoppingCartPage = () => {
  const shoppingCart = useStoreState((state) => state.shoppingCart.shoppingCart);
  const addToCart = useStoreActions((actions) => actions.shoppingCart.addToCart);
  const removeFromCart = useStoreActions((actions) => actions.shoppingCart.removeFromCart);
  const emptyCart = useStoreActions((actions) => actions.shoppingCart.emptyCart);
  const [currentStep, setcurrentStep] = useState(1);
  const [shippingCost, setShippingCost] = useState();
  const [paymentMethod, setPaymentMethod] = useState();
  const [selectedShippingMethod, setSelectedShippingMethod] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [processingPurchase, setProcessingPurchase] = useState(false);
  const [activeShippingMethods, setActiveShippingMethods] = useState(shippingMethods);
  const purchaseTotal = useRef();
  const listInfo = useRef();

  const handleAddItem = (item) => {
    addToCart(item);
  };

  const handleRemoveItem = (item) => {
    removeFromCart(item);
  };

  const getTotal = () => {
    let total = 0;
    shoppingCart.forEach((element) => {
      total += element.total;
    });
    if (shippingCost) {
      total += shippingCost;
    }
    purchaseTotal.current = total;
    return total.toFixed(2);
  };

  const handleShippingMethodChange = (shippingMethodId) => {
    const chosenShippingMethod = activeShippingMethods.find(
      (shippingMethod) => shippingMethod.id === parseInt(shippingMethodId, 10)
    );
    if (chosenShippingMethod) {
      setSelectedShippingMethod(chosenShippingMethod);
      setShippingCost(chosenShippingMethod.shippingPrice);
    } else {
      setSelectedShippingMethod();
      setShippingCost();
    }
  };

  const handlePaymentMethodChange = (paymentMethodId) => {
    const selectedPaymentMethod = paymentMethods.find(
      (method) => method.id === parseInt(paymentMethodId, 10)
    );
    setPaymentMethod(selectedPaymentMethod);
  };

  const handleListClick = () => {
    const info = {
      items: shoppingCart,
      shippingMethod: selectedShippingMethod.name,
      paymentMethod: paymentMethod.name,
      total: purchaseTotal.current,
    };
    listInfo.current = info;
    setcurrentStep(2);
  };

  const goToStep1 = () => {
    if (currentStep === 2) {
      setcurrentStep(1);
    }
  };

  const getVersion = (item) => {
    switch (item.isDigital) {
      case true:
        return 'versión digital';
      case false:
        return 'versión impresa';
      case 'product':
        return 'obra';
      default:
        return 'versión impresa';
    }
  };

  useEffect(() => {
    if (shoppingCart.length) {
      const found = shoppingCart.find(
        (element) => element.isDigital === false || element.isDigital === 'product'
      );
      if (found) {
        const shippingCopy = [...shippingMethods];
        const index = shippingCopy.findIndex((element) => element.id === 3);
        shippingCopy.splice(index, 1);
        setActiveShippingMethods(shippingCopy);
      } else {
        setActiveShippingMethods(shippingMethods);
      }
    }
  }, [shoppingCart]);

  useEffect(() => {
    document.title = 'Eufonía - Carrito de compras'; // eslint-disable-line
  }, []);

  const step1Content = (
    <>
      <Header>
        <Column>ARTÍCULO</Column>
        <Column>CANTIDAD</Column>
        <Column>PRECIO UNITARIO</Column>
        <Column>PRECIO TOTAL</Column>
      </Header>
      {shoppingCart.map((item) => (
        <CartItem key={item.id}>
          <Column>
            <p>{item.title}</p>
            <Author>{item.author}</Author>
            <Version>{getVersion(item)}</Version>
          </Column>
          <Column>
            <ItemNumberWrapper>
              <Sign onClick={() => handleRemoveItem(item)}>
                <img src={MinusIcon} alt="" />
              </Sign>
              <ItemNumber>{item.quantity}</ItemNumber>
              <Sign onClick={() => handleAddItem(item)}>
                <img src={PlusIcon} alt="" />
              </Sign>
            </ItemNumberWrapper>
          </Column>
          <Column>
            <Price>{item.price.toFixed(2)}</Price>
          </Column>
          <Column>
            <Price>{item.total.toFixed(2)}</Price>
          </Column>
        </CartItem>
      ))}
      <Shipping>
        <Column>
          <ShippingTitle>GASTOS DE ENVÍO</ShippingTitle>
        </Column>
        <ColumnDouble>
          <CustomSelect
            onChange={(e) => handleShippingMethodChange(e.target.value)}
            value={selectedShippingMethod?.id}
          >
            <option value={0}>seleccionar tipo de envío</option>
            {activeShippingMethods &&
              activeShippingMethods.map((shippingMethod) => (
                <option key={shippingMethod.id} value={shippingMethod.id}>
                  {shippingMethod.name}
                </option>
              ))}
          </CustomSelect>
          <CustomSelect
            onChange={(e) => handlePaymentMethodChange(e.target.value)}
            value={paymentMethod?.id}
          >
            <option value={0}>seleccionar forma de pago</option>
            {paymentMethods &&
              paymentMethods.map((method) => (
                <option key={method.id} value={method.id}>
                  {method.name}
                </option>
              ))}
          </CustomSelect>
        </ColumnDouble>
        <Column>
          <ShippingPrice>{shippingCost ? shippingCost.toFixed(2) : '--'}</ShippingPrice>
        </Column>
      </Shipping>
      <ShippingTotal>
        <Column>TOTAL</Column>
        <ColumnDouble />
        <Column>{getTotal()}</Column>
      </ShippingTotal>
      <ButtonWrapper>
        <SideButton text="Vaciar carrito" onClick={() => emptyCart()} />
        <Button
          text="Continuar con el pedido"
          onClick={() => handleListClick()}
          disabled={!selectedShippingMethod || !paymentMethod}
        />
      </ButtonWrapper>
    </>
  );

  const validationSchema = Yup.object({
    name: Yup.string().required(),
    lastName: Yup.string().required(),
    document: Yup.string().required(),
    country: Yup.string().required(),
    province: Yup.string().required(),
    city: Yup.string().required(),
    address: Yup.string().required(),
    phone: Yup.string().required(),
    email: Yup.string().required(),
  });

  const formikBag = useFormik({
    initialValues: {
      name: '',
      lastName: '',
      document: '',
      country: '',
      province: '',
      city: '',
      address: '',
      phone: '',
      email: '',
      comments: '',
      withInvoice: true,
    },
    validationSchema,
    validateOnChange: false,
  });

  const { values, handleChange, errors, validateForm, setFieldValue } = formikBag;

  const handleFormClick = async (e) => {
    e.preventDefault();
    const validationErrors = await validateForm();
    if (!Object.values(validationErrors).length) {
      setProcessingPurchase(true);
      const response = await createPurchase({ ...values, ...listInfo.current });
      if (response === 'success') {
        setcurrentStep(3);
        emptyCart();
      }
      if (response === 'error') {
        setErrorMessage('Ha ocurrido un error al generar la compra');
      }
      setProcessingPurchase(false);
    }
  };

  useEffect(() => {
    if (selectedShippingMethod) {
      if (selectedShippingMethod.id !== 3) {
        setFieldValue('country', 'Ecuador');
      } else {
        setFieldValue('country', '');
      }
    }
  }, [selectedShippingMethod]);

  const step2Content = (
    <>
      <CustomForm>
        <FieldLabel htmlFor="name" error={errors.name}>
          Nombres*
        </FieldLabel>
        <Field
          type="text"
          id="name"
          name="name"
          value={values.name}
          onChange={handleChange}
          error={errors.name}
        />
        <FieldLabel htmlFor="lastName" error={errors.lastName}>
          Apellidos*
        </FieldLabel>
        <Field
          type="text"
          id="lastName"
          name="lastName"
          value={values.lastName}
          onChange={handleChange}
          error={errors.lastName}
        />
        <FieldLabel htmlFor="document" error={errors.document}>
          C.I. o RUC*
        </FieldLabel>
        <Field
          type="text"
          id="document"
          name="document"
          value={values.document}
          onChange={handleChange}
          error={errors.document}
        />
        <FieldLabel htmlFor="country" error={errors.country}>
          País*
        </FieldLabel>
        <Field
          type="text"
          id="country"
          name="country"
          value={values.country}
          onChange={handleChange}
          error={errors.country}
          disabled={selectedShippingMethod?.id !== 3}
        />
        <FieldLabel htmlFor="province" error={errors.province}>
          Provincia*
        </FieldLabel>
        <Field
          type="text"
          id="province"
          name="province"
          value={values.province}
          onChange={handleChange}
          error={errors.province}
        />
        <FieldLabel htmlFor="city" error={errors.city}>
          Ciudad*
        </FieldLabel>
        <Field
          type="text"
          id="city"
          name="city"
          value={values.city}
          onChange={handleChange}
          error={errors.city}
        />
        <FieldLabel htmlFor="address" error={errors.address}>
          Dirección exacta*
        </FieldLabel>
        <Textarea
          type="text"
          id="address"
          name="address"
          value={values.address}
          onChange={handleChange}
          error={errors.address}
        />
        <FieldLabel htmlFor="phone" error={errors.phone}>
          Teléfono celular*
        </FieldLabel>
        <Field
          type="text"
          id="phone"
          name="phone"
          value={values.phone}
          onChange={handleChange}
          error={errors.phone}
        />
        <FieldLabel htmlFor="email" error={errors.email}>
          Correo electrónico*
        </FieldLabel>
        <Field
          type="text"
          id="email"
          name="email"
          value={values.email}
          onChange={handleChange}
          error={errors.email}
        />
        <FieldLabel htmlFor="comments">Comentarios sobre el pedido</FieldLabel>
        <Textarea
          type="text"
          id="comments"
          name="comments"
          value={values.comments}
          onChange={handleChange}
        />
        <Checkbox>
          <input
            type="checkbox"
            id="withInvoice"
            name="withInvoice"
            checked={values.withInvoice}
            onChange={(e) => setFieldValue('withInvoice', e.target.checked)}
          />
          Deseo que me envien factura
        </Checkbox>
        <ButtonWrapper>
          <SideButton text="Regresar" onClick={() => goToStep1()} />
          <Button text="Enviar pedido" onClick={(e) => handleFormClick(e)} />
        </ButtonWrapper>
      </CustomForm>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </>
  );

  const step3Content = (
    <SuccessfulPurchaseImageWrapper>
      <SuccessfulPurchaseImage src={SuccessfulPurchase} alt="successful purchase" />
    </SuccessfulPurchaseImageWrapper>
  );

  return (
    <>
      <Layout>
        <Wrapper finalStep={currentStep === 3}>
          <StepsWrapper>
            <h2>COMPRAS</h2>
            <Steps>
              <Step onClick={() => goToStep1()}>
                <StepLetter active={currentStep === 1}>A</StepLetter>
                <StepText active={currentStep === 1}>Lista de compras</StepText>
                <StepArrow src={ArrowIcon} alt="" />
              </Step>
              <Step>
                <StepLetter active={currentStep === 2}>B</StepLetter>
                <StepText active={currentStep === 2}>Datos de facturación</StepText>
                <StepArrow src={ArrowIcon} alt="" />
              </Step>
              <Step>
                <StepLetter active={currentStep === 3}>C</StepLetter>
                <StepText active={currentStep === 3}>Pedido enviado</StepText>
                <StepArrow src={ArrowIcon} alt="" />
              </Step>
            </Steps>
          </StepsWrapper>
          {(currentStep === 1 || currentStep === 2) && (
            <CartContent>
              {currentStep === 1 && step1Content}
              {currentStep === 2 && step2Content}
            </CartContent>
          )}
          {currentStep === 3 && step3Content}
        </Wrapper>
      </Layout>
      {(currentStep === 1 || currentStep === 2) && !shoppingCart.length && <Redirect to="/" />}
      {processingPurchase && (
        <ImageOverlay>
          <ProcessingPurchaseImage src={ProcessingPurchase} alt="" />
          <ProcessingPurchaseText>Estamos procesando tu pedido...</ProcessingPurchaseText>
        </ImageOverlay>
      )}
    </>
  );
};

const Wrapper = styled.div`
  border-top: 2px solid ${colors.white};
  border-bottom: 2px solid ${colors.white};
  flex: 1;
  background-color: ${(props) => (props.finalStep ? colors.purchaseBackground : colors.white)};
`;

const StepsWrapper = styled.div`
  padding: 36px 50px;
  background-color: ${colors.textBackground};

  h2 {
    color: ${colors.mainBlue};
    font-family: 'RobotoBold';
    font-size: 24px;
    margin: 0;
    margin-bottom: 15px;

    @media (min-width: ${breakpoints.md}px) {
      font-size: 36px;
    }
  }
`;

const Steps = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Step = styled.button`
  display: flex;
  align-items: center;
  padding: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  text-align: left;
  margin-right: 5px;
  margin-bottom: 5px;

  &:last-child {
    img {
      display: none;
    }
  }
`;

const StepLetter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.mainBlue};
  width: 30px;
  height: 30px;
  color: ${colors.white};
  font-family: 'RobotoBold';
  font-size: 20px;
  color: ${(props) => (props.active ? `${colors.white}` : `${colors.textBackground}`)};

  @media (min-width: ${breakpoints.md}px) {
    width: 50px;
    height: 50px;
    font-size: 36px;
  }
`;

const StepText = styled.div`
  font-size: 12px;
  width: 60px;
  margin: 0 15px 0 7px;
  font-family: ${(props) => (props.active ? 'RobotoSlabBold' : 'RobotoSlabLight')};
  color: ${(props) => (props.active ? `${colors.white}` : `${colors.mainBlue}`)};

  @media (min-width: ${breakpoints.md}px) {
    font-size: 14px;
    width: 75px;
  }
`;

const StepArrow = styled.img`
  width: 12px;
  height: 22px;
`;

const CartContent = styled.div`
  padding: 40px 50px;

  @media (min-width: ${breakpoints.lg}px) {
    width: 800px;
  }
`;

const Header = styled.div`
  display: flex;
  padding-bottom: 10px;
  border-bottom: 2px solid ${colors.mainBlue};
  color: ${colors.mainBlue};
  font-family: 'RobotoSlabBold';
  font-size: 10px;
  text-align: center;

  @media (min-width: ${breakpoints.md}px) {
    font-size: 12px;
  }

  @media (min-width: ${breakpoints.lg}px) {
    font-size: 14px;
  }
`;

const CartItem = styled.div`
  display: flex;
  margin-top: 10px;
  color: ${colors.mainBlue};
  font-family: 'RobotoSlabBold';
  font-size: 10px;
  text-align: center;
  border-bottom: 1px solid ${colors.mainBlue};
  padding-bottom: 10px;

  @media (min-width: ${breakpoints.md}px) {
    font-size: 12px;
  }

  @media (min-width: ${breakpoints.lg}px) {
    font-size: 14px;
  }
`;

const Author = styled.p`
  font-family: 'RobotoSlabLight';
`;

const Version = styled.p`
  font-family: 'RobotoBold';
  font-style: italic;
`;

const Column = styled.div`
  width: 25%;

  p {
    margin: 0;
    margin-bottom: 4px;
  }

  &:first-child {
    text-align: left;
  }
`;
const ColumnDouble = styled.div`
  width: 50%;
`;

const Sign = styled.button`
  width: 20px;
  height: 20px;
  background-color: ${colors.mainBlue};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 0;
  flex-shrink: 0;
`;

const ItemNumberWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const ItemNumber = styled.div`
  margin: 0 10%;
  display: flex;
  align-items: center;
  font-family: 'RobotoSlabLight';
`;

const Price = styled.div`
  font-family: 'RobotoSlabLight';
  text-align: center;
`;

const Shipping = styled.div`
  display: flex;
  font-size: 10px;
  color: ${colors.mainBlue};
  align-items: center;
  margin-top: 5px;
  align-items: flex-start;
  border-bottom: 1px solid ${colors.mainBlue};

  @media (min-width: ${breakpoints.md}px) {
    font-size: 12px;
  }

  @media (min-width: ${breakpoints.lg}px) {
    font-size: 14px;
  }
`;

const ShippingTitle = styled.div`
  font-family: 'RobotoSlabBold';
  margin-top: 5px;
`;

const CustomSelect = styled.select`
  width: 80%;
  margin-left: 10px;
  margin-bottom: 5px;
  border-color: ${colors.mainBlue};
  border-width: 1px;
  font-family: 'RobotoBold';
  font-size: 10px;
  color: ${colors.mainBlue};
  text-align: center;
  padding: 2px 0;
  background-color: ${colors.white};

  @media (min-width: ${breakpoints.md}px) {
    font-size: 12px;
  }

  @media (min-width: ${breakpoints.lg}px) {
    font-size: 14px;
  }
`;

const ShippingPrice = styled(Price)`
  margin-top: 5px;
`;

const ShippingTotal = styled(Header)`
  padding-bottom: 0;
  border-bottom: none;
  margin-top: 10px;
`;

const ButtonWrapper = styled.div`
  margin-top: 35px;
  display: flex;
`;

const SideButton = styled(Button)`
  margin-right: 20px;
`;

const CustomForm = styled.form`
  @media (min-width: ${breakpoints.md}px) {
    width: 600px;
  }
`;

const FieldLabel = styled.label`
  display: block;
  font-family: 'RobotoSlabBold';
  font-size: 12px;
  margin-bottom: 2px;
  color: ${(props) => (props.error ? '#ff0033' : `${colors.mainBlue}`)};

  @media (min-width: ${breakpoints.lg}px) {
    font-size: 14px;
  }
`;

const Checkbox = styled.label`
  display: block;
  font-family: 'RobotoSlabLight';
  font-size: 12px;
  margin-bottom: 50px;
  color: ${colors.mainBlue};
  display: flex;
  align-items: center;

  @media (min-width: ${breakpoints.lg}px) {
    font-size: 14px;
  }
`;

const Field = styled.input`
  width: 100%;
  height: 20px;
  font-family: 'RobotoRegular';
  font-size: 12px;
  color: ${colors.mainBlue};
  margin-bottom: 15px;
  padding: 1px 5px;
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) => (props.error ? '#ff0033' : `${colors.mainBlue}`)};

  @media (min-width: ${breakpoints.lg}px) {
    font-size: 14px;
  }
`;

const Textarea = styled.textarea`
  width: 100%;
  height: 60px;
  font-family: 'RobotoRegular';
  font-size: 12px;
  color: ${colors.mainBlue};
  margin-bottom: 15px;
  box-sizing: border-box;
  resize: none;
  padding: 2px 5px;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) => (props.error ? '#ff0033' : `${colors.mainBlue}`)};

  @media (min-width: ${breakpoints.lg}px) {
    font-size: 14px;
  }
`;

const ErrorMessage = styled.div`
  text-align: center;
  color: #ff0033;
  margin-top: 15px;
  font-size: 14px;
  width: 100%;

  @media (min-width: ${breakpoints.md}px) {
    width: 60%;
  }
`;

const SuccessfulPurchaseImageWrapper = styled.div`
  border-top: 2px solid ${colors.white};
  background-color: ${colors.purchaseBackground};
  width: 100%;
`;

const SuccessfulPurchaseImage = styled.img`
  width: 100%;
  max-width: 1200px;
`;

const ImageOverlay = styled.div`
  width: 100vw;
  height: 100vh;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.7);
`;

const ProcessingPurchaseText = styled.div`
  font-family: 'RobotoSlabBold';
  font-size: 24px;
  color: ${colors.white};
  margin-top: 10px;
`;

const ProcessingPurchaseImage = styled.img`
  animation: rotation 2s infinite linear;
`;

export default ShoppingCartPage;
