import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useStoreState, useStoreActions } from 'easy-peasy';
import PlusIcon from '../../assets/images/plus-icon.svg';
import WhiteCartIcon from '../../assets/images/white-cart-icon.svg';
import BlueDiamondIcon from '../../assets/images/blue-diamond-icon.svg';
import MinusOrangeDiamondIcon from '../../assets/images/minus-orange-diamond-icon.svg';
import OrangeTailIcon from '../../assets/images/orange-tail-icon.svg';
import { colors, breakpoints } from '../../config/constants';

const PriceElement = ({ itemId, text, price, forGallery, item, digital }) => {
  const shoppingCart = useStoreState((state) => state.shoppingCart.shoppingCart);
  const addToCart = useStoreActions((actions) => actions.shoppingCart.addToCart);
  const removeFromCart = useStoreActions((actions) => actions.shoppingCart.removeFromCart);

  const getBook = () => shoppingCart.find((shoppingCartItem) => shoppingCartItem.id === itemId);

  const handleAddBook = () => {
    addToCart({
      id: digital ? item.digitalId : item.id,
      quantity: 1,
      price: digital ? item.digitalPrice : item.price,
      title: item.title,
      author: item.author || item.artist,
      isDigital: digital,
    });
  };

  const handleRemoveBook = () => {
    removeFromCart({
      id: digital ? item.digitalId : item.id,
      quantity: 1,
      price: digital ? item.digitalPrice : item.price,
      title: item.title,
      author: item.author || item.artist,
      isDigital: digital,
    });
  };

  return (
    <Wrapper forGallery={forGallery}>
      <PlusSign onClick={() => handleAddBook()}>
        <img src={PlusIcon} alt="" />
      </PlusSign>
      <PriceWrapper forGallery={forGallery}>
        <PriceSection>
          <ShoppingCart src={WhiteCartIcon} alt="icono carrito" />
          <PriceSectionType>{text}</PriceSectionType>
          <PriceSectionPrice>{`$ ${price.toFixed(2)} USD`}</PriceSectionPrice>
        </PriceSection>
        <PriceSectionTail src={OrangeTailIcon} alt="" />
      </PriceWrapper>
      {!!getBook() && (
        <>
          <ItemsNumber>{getBook().quantity}</ItemsNumber>
          <MinusSign onClick={() => handleRemoveBook()} />
        </>
      )}
    </Wrapper>
  );
};

const mobileHeight = '26px';
const desktopHeight = '30px';

const Wrapper = styled.div`
  display: flex;
  margin-bottom: ${(props) => (props.forGallery ? '0' : '2px')};

  @media (min-width: ${breakpoints.lg}px) {
    margin-bottom: ${(props) => (props.forGallery ? '0' : '6px')};
  }
`;

const PlusSign = styled.button`
  width: ${mobileHeight};
  height: ${mobileHeight};
  background-color: ${colors.mainBlue};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 0;
  flex-shrink: 0;

  @media (min-width: ${breakpoints.lg}px) {
    width: ${desktopHeight};
    height: ${desktopHeight};
  }
`;

const PriceWrapper = styled.div`
  display: flex;
  flex: 1;

  @media (min-width: ${breakpoints.sm}px) {
    flex: inherit;
    min-width: ${(props) => (props.forGallery ? 'auto' : '250px')};
  }
`;

const PriceSection = styled.div`
  background-color: ${colors.mainOrange};
  height: ${mobileHeight};
  padding-right: 10px;
  padding-left: 5px;
  display: flex;
  align-items: center;
  font-size: 10px;
  color: ${colors.mainBlue};
  flex: 1;

  img {
    width: 15px;
    height: 15px;
    margin: 0 7px;
  }

  @media (min-width: ${breakpoints.sm}px) {
    padding-left: 0;
    font-size: 12px;
  }

  @media (min-width: ${breakpoints.lg}px) {
    height: ${desktopHeight};

    img {
      width: 20px;
      height: 20px;
    }
  }
`;

const ShoppingCart = styled.img`
  display: none;

  @media (min-width: ${breakpoints.sm}px) {
    display: block;
  }
`;

const PriceSectionType = styled.p`
  font-family: 'RobotoSlabBold';
  margin: 0;
  margin-right: 4px;

  @media (min-width: ${breakpoints.lg}px) {
    font-size: 14px;
  }
`;

const PriceSectionPrice = styled.p`
  font-family: 'RobotoSlabLight';
  margin: 0;

  @media (min-width: ${breakpoints.lg}px) {
    font-size: 14px;
  }
`;

const PriceSectionTail = styled.img`
  height: ${mobileHeight};
  margin-left: -1px;

  @media (min-width: ${breakpoints.lg}px) {
    height: ${desktopHeight};
  }
`;

const ItemsNumber = styled.div`
  font-family: 'RobotoBold';
  background-image: url(${BlueDiamondIcon});
  background-size: cover;
  width: ${mobileHeight};
  height: ${mobileHeight};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.white};
  margin-left: -13px;
  font-size: 12px;
  flex-shrink: 0;

  @media (min-width: ${breakpoints.lg}px) {
    width: ${desktopHeight};
    height: ${desktopHeight};
    margin-left: -15px;
  }
`;

const MinusSign = styled.button`
  font-family: 'RobotoBold';
  background-image: url(${MinusOrangeDiamondIcon});
  background-size: cover;
  width: ${mobileHeight};
  height: ${mobileHeight};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.white};
  font-size: 12px;
  cursor: pointer;
  border: 0;
  background-color: transparent;
  padding: 0;
  flex-shrink: 0;

  @media (min-width: ${breakpoints.lg}px) {
    width: ${desktopHeight};
    height: ${desktopHeight};
  }
`;

PriceElement.propTypes = {
  itemId: PropTypes.string,
  text: PropTypes.string,
  price: PropTypes.number,
  forGallery: PropTypes.bool,
  digital: PropTypes.bool,
  item: PropTypes.shape({
    title: PropTypes.string,
    author: PropTypes.string,
    artist: PropTypes.string,
    id: PropTypes.string,
    digitalId: PropTypes.string,
    price: PropTypes.number,
    digitalPrice: PropTypes.number,
  }),
};

PriceElement.defaultProps = {
  itemId: '',
  text: '',
  price: null,
  forGallery: false,
  digital: false,
  item: {},
};

export default PriceElement;
