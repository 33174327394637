import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Layout from '../../components/Layout';
import Button from '../../components/Button';
import LocationIcon from '../../assets/images/location-icon.svg';
import EnvolopeIcon from '../../assets/images/envolope-icon.svg';
import WhatsappIcon from '../../assets/images/whatsapp-icon.svg';
import ManuscriptIcon from '../../assets/images/manuscript-icon.svg';
import OrangeTailIcon from '../../assets/images/orange-tail-icon.svg';
import { colors, breakpoints } from '../../config/constants';
import { createComment } from '../../config/contentfulAPI';

const AboutPage = () => {
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const validationSchema = Yup.object({
    name: Yup.string().required(),
    email: Yup.string().email().required(),
    comments: Yup.string().required(),
  });

  const formikBag = useFormik({
    initialValues: {
      name: '',
      email: '',
      comments: '',
    },
    validationSchema,
    validateOnChange: false,
  });

  const { values, handleChange, errors, validateForm, setFieldValue } = formikBag;

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = await validateForm();
    if (!Object.values(validationErrors).length) {
      setLoading(true);
      const response = await createComment(values);
      if (response === 'success') {
        setSuccessMessage('Tu mensaje se ha enviado');
      }
      if (response === 'error') {
        setErrorMessage('Ha ocurrido un error enviando el mensaje');
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    document.title = 'Eufonía - Contáctanos'; // eslint-disable-line
  }, []);

  return (
    <Layout>
      <Wrapper>
        <Title>CONTÁCTANOS</Title>
        <ContactsWrapper>
          <Contact>
            <Bullet src={WhatsappIcon} alt="" />
            <Name>+593 98 465 5244</Name>
          </Contact>
          <Contact>
            <Bullet src={EnvolopeIcon} alt="" />
            <Name>eufoniaediciones@gmail.com</Name>
          </Contact>
          <Contact>
            <Bullet src={LocationIcon} alt="" />
            <Name>Quito, Ecuador</Name>
          </Contact>
        </ContactsWrapper>
        {false && (
          <SendManuscript>
            <ManuscriptIconWrapper>
              <img src={ManuscriptIcon} alt="" />
            </ManuscriptIconWrapper>
            <ManuscriptText>
              <p>
                <Bold>Enviar manuscrito</Bold> (archivo PDF o DOCX)
              </p>
            </ManuscriptText>
            <ManuscriptTail src={OrangeTailIcon} alt="" />
          </SendManuscript>
        )}
        <ContactForm>
          <FieldLabel htmlFor="name" error={errors.name}>
            Nombre:
          </FieldLabel>
          <Field
            type="text"
            id="name"
            name="name"
            value={values.name}
            onChange={handleChange}
            error={errors.name}
          />
          <FieldLabel htmlFor="email" error={errors.email}>
            Correo electrónico:
          </FieldLabel>
          <Field
            type="text"
            id="email"
            name="email"
            value={values.email}
            onChange={handleChange}
            error={errors.email}
          />
          <FieldLabel htmlFor="comments" error={errors.comments}>
            Comentarios:
          </FieldLabel>
          <TextArea
            id="comments"
            name="comments"
            rows="4"
            cols="50"
            error={errors.comments}
            onChange={(e) => {
              setFieldValue('comments', e.target.value);
              setSuccessMessage();
              setErrorMessage();
            }}
          />
          <Button text="Enviar comentarios" onClick={(e) => handleSubmit(e)} disabled={loading} />
          {successMessage && <SuccessMessage>{successMessage}</SuccessMessage>}
          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </ContactForm>
      </Wrapper>
    </Layout>
  );
};

const Wrapper = styled.div`
  padding: 45px 50px;

  @media (min-width: ${breakpoints.md}px) {
    padding: 45px 100px;
  }

  @media (min-width: ${breakpoints.lg}px) {
    padding: 45px 300px;
  }
`;

const Title = styled.h2`
  color: ${colors.mainBlue};
  font-family: 'RobotoBold';
  font-size: 24px;
  margin: 0;
  margin-bottom: 10px;
`;

const ContactsWrapper = styled.div`
  margin-bottom: 25px;
`;

const Contact = styled.div`
  display: flex;
  align-items: center;
`;

const Bullet = styled.img`
  width: 11px;
  margin-right: 7px;
`;

const Name = styled.p`
  margin: 0;
  color: ${colors.mainBlue};
  font-family: 'RobotoSlabLight';
  font-size: 12px;

  @media (min-width: ${breakpoints.lg}px) {
    font-size: 14px;
  }
`;

const SendManuscript = styled.div`
  display: flex;
  margin-bottom: 25px;
`;

const ManuscriptIconWrapper = styled.div`
  width: 25px;
  height: 25px;
  background-color: ${colors.mainBlue};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ManuscriptText = styled.div`
  height: 25px;
  background-color: ${colors.mainOrange};
  font-family: 'RobotoSlabLight';
  color: ${colors.mainBlue};
  font-size: 12px;
  display: flex;
  align-items: center;
  padding: 0 7px;

  p {
    margin: 0;
  }
`;

const Bold = styled.span`
  font-family: 'RobotoSlabBold';
`;

const ManuscriptTail = styled.img`
  height: 25px;
`;

const ContactForm = styled.form`
  color: ${colors.mainBlue};
  font-family: 'RobotoSlabRegular';
  font-size: 12px;
`;

const TextArea = styled.textarea`
  width: 100%;
  height: 200px;
  padding: 10px;
  font-family: 'RobotoRegular';
  font-size: 12px;
  color: ${colors.mainBlue};
  resize: none;
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) => (props.error ? '#ff0033' : `${colors.mainBlue}`)};
  margin-bottom: 13px;

  @media (min-width: ${breakpoints.md}px) {
    width: 60%;
  }

  @media (min-width: ${breakpoints.lg}px) {
    font-size: 14px;
  }
`;

const FieldLabel = styled.label`
  display: block;
  font-family: 'RobotoSlabBold';
  font-size: 12px;
  margin-bottom: 2px;
  color: ${(props) => (props.error ? '#ff0033' : `${colors.mainBlue}`)};

  @media (min-width: ${breakpoints.lg}px) {
    font-size: 14px;
  }
`;

const Field = styled.input`
  width: 100%;
  height: 20px;
  font-family: 'RobotoRegular';
  font-size: 12px;
  color: ${colors.mainBlue};
  margin-bottom: 15px;
  padding: 1px 5px;
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) => (props.error ? '#ff0033' : `${colors.mainBlue}`)};

  @media (min-width: ${breakpoints.md}px) {
    width: 60%;
  }

  @media (min-width: ${breakpoints.lg}px) {
    font-size: 14px;
  }
`;

const SuccessMessage = styled.div`
  text-align: center;
  color: ${colors.mainBlue};
  margin-top: 15px;
  font-size: 18px;
  width: 100%;
  text-transform: uppercase;

  @media (min-width: ${breakpoints.md}px) {
    width: 60%;
  }
`;

const ErrorMessage = styled.div`
  text-align: center;
  color: #ff0033;
  margin-top: 15px;
  font-size: 14px;
  width: 100%;

  @media (min-width: ${breakpoints.md}px) {
    width: 60%;
  }
`;

export default AboutPage;
