import { createStore, persist } from 'easy-peasy';
import shoppingCart from './shoppingCart';

const storeModel = {
  shoppingCart,
};

const store = createStore(persist(storeModel));

export default store;
