import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import BulletIcon from '../../assets/images/bullet-icon-blue.svg';
import { colors, breakpoints } from '../../config/constants';

const PersonInfo = ({ author, isIllustrator }) => (
  <Wrapper>
    <ContentWrapper>
      <ImageWrapper>
        <img src={author.photoUrl} alt="" />
        <MobileName>{author.name}</MobileName>
      </ImageWrapper>
      <Text isIllustrator={isIllustrator}>
        <DesktopName>
          <Bullet src={BulletIcon} alt="" />
          <span>{author.name}</span>
        </DesktopName>
        <Description>{documentToReactComponents(author.description)}</Description>
        {author.link && (
          <a href={author.link} target="_blank" rel="noreferrer">
            {author.linkText}
          </a>
        )}
      </Text>
    </ContentWrapper>
    <div className="bottom-separator" />
  </Wrapper>
);

const Wrapper = styled.div`
  border-top: 2px solid ${colors.white};

  .bottom-separator {
    width: 100%;
    height: 11px;
    background-color: ${colors.mainOrange};
  }

  &:last-child {
    .bottom-separator {
      display: none;
    }
  }
`;

const ContentWrapper = styled.div`
  @media (min-width: ${breakpoints.md}px) {
    display: flex;
  }
`;

const ImageWrapper = styled.div`
  border-bottom: 2px solid ${colors.white};

  @media (min-width: 410px) {
    display: flex;
  }

  img {
    width: 100%;

    @media (min-width: 410px) {
      width: 200px;
      height: 200px;
    }

    @media (min-width: ${breakpoints.md}px) {
      width: 400px;
      height: 400px;
    }
  }
`;

const MobileName = styled.div`
  flex: 1;
  background-color: ${colors.textBackground};
  color: ${colors.mainBlue};
  display: none;
  justify-content: center;
  align-items: center;
  font-family: 'RobotoBold';
  font-size: 36px;
  border-left: 2px solid ${colors.white};
  text-align: center;
  padding: 0 20px;

  @media (min-width: 410px) {
    display: flex;
  }

  @media (min-width: ${breakpoints.md}px) {
    display: none;
  }
`;

const DesktopName = styled.div`
  color: ${colors.mainBlue};
  font-family: 'RobotoBold';
  font-size: 24px;
  margin-bottom: 9px;

  @media (min-width: 410px) {
    display: none;
  }

  @media (min-width: ${breakpoints.md}px) {
    display: block;
  }

  @media (min-width: ${breakpoints.lg}px) {
    font-size: 36px;
  }
`;

const Bullet = styled.img`
  margin-right: 5px;
  @media (min-width: ${breakpoints.lg}px) {
    width: 20px;
    height: 20px;
  }
`;

const Text = styled.div`
  color: ${colors.mainBlue};
  padding: 26px 50px 26px;
  font-family: 'RobotoSlabLight';
  font-size: ${(props) => (props.isIllustrator ? '10px' : '12px')};

  p {
    margin: 0;
  }

  a {
    font-family: 'RobotoSlabRegular';
    font-weight: bold;
    color: ${colors.mainBlue};
  }

  @media (min-width: ${breakpoints.md}px) {
    padding: 0 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 308px;
  }

  @media (min-width: ${breakpoints.lg}px) {
    font-size: ${(props) => (props.isIllustrator ? '12px' : '14px')};
    width: 500px;
  }
`;

const Description = styled.div`
  p {
    margin-bottom: 11px;
    line-height: 14px;

    @media (min-width: ${breakpoints.lg}px) {
      margin-bottom: 17px;
      line-height: normal;
    }
  }
`;

PersonInfo.propTypes = {
  author: PropTypes.shape({
    name: PropTypes.string,
    photoUrl: PropTypes.string,
    description: PropTypes.shape({}),
    link: PropTypes.string,
    linkText: PropTypes.string,
  }),
  isIllustrator: PropTypes.bool,
};

PersonInfo.defaultProps = {
  author: {},
  isIllustrator: false,
};

export default PersonInfo;
