import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import Layout from '../../components/Layout';
import PersonInfo from '../../components/PersonInfo';
import GET_AUTHORS from './queries';

const parseAuthors = (authors) => {
  const parsedData = authors.authorCollection.items.map((author) => {
    const parsedAuthor = {
      id: author.sys.id,
      name: author.name,
      photoUrl: author.photo.url,
      description: author.description.json,
      link: author.link,
      linkText: author.linkText,
    };
    return parsedAuthor;
  });
  return parsedData;
};

const AuthorsPage = () => {
  const { data: authors } = useQuery(GET_AUTHORS);

  useEffect(() => {
    document.title = 'Eufonía - Autores'; // eslint-disable-line
  }, []);

  return (
    <Layout>
      {authors &&
        parseAuthors(authors).map((author) => <PersonInfo key={author.id} author={author} />)}
    </Layout>
  );
};

export default AuthorsPage;
