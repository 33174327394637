import { gql } from '@apollo/client';

const GET_AUTHORS = gql`
  query GetAuthors {
    authorCollection(order: [name_ASC]) {
      items {
        sys {
          id
        }
        name
        description {
          json
        }
        photo {
          url
        }
        link
        linkText
      }
    }
  }
`;

export default GET_AUTHORS;
