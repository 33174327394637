import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import PriceElement from '../PriceElement';
import PrevArrowIcon from '../../assets/images/prev-arrow-icon.svg';
import EmptySliderDot from '../../assets/images/empty-slider-dot.svg';
import FullSliderDot from '../../assets/images/full-slider-dot.svg';
import BlueTailIcon from '../../assets/images/blue-tail-icon.svg';
import Characteristics from '../Characteristics';
import { colors, breakpoints } from '../../config/constants';

const PrevArrow = (props) => {
  const { onClick } = props; // eslint-disable-line
  return <PrevArrowImage src={PrevArrowIcon} alt="" onClick={onClick} />;
};

const NextArrow = (props) => {
  const { onClick } = props; // eslint-disable-line
  return <NextArrowImage src={PrevArrowIcon} alt="" onClick={onClick} />;
};

const customDots = () => <div className="custom-dot" />;

const sliderSettings = {
  dots: true,
  dotsClass: 'custom-slick-dots',
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  adaptiveHeight: true,
  prevArrow: <PrevArrow />,
  nextArrow: <NextArrow />,
  customPaging: customDots,
};

const parseCharacteristics = (characteristics) => {
  const parsedCharacteristics = characteristics.map((characteristic) => ({
    title: characteristic.title.title,
    value: characteristic.value.value,
  }));
  return parsedCharacteristics;
};

const BookInfo = ({ book }) => (
  <Wrapper>
    <ContentWrapper>
      <Images>
        <CategoryBanner>
          <CategoryBannerText>{book.category}</CategoryBannerText>
          <CategoryBannerTail src={BlueTailIcon} alt="" />
        </CategoryBanner>
        <Slider {...sliderSettings}>
          {book.images.map((image, index) => (
            <SliderImage key={`image-${index + 1}`} src={image.url} alt={image.title} />
          ))}
        </Slider>
      </Images>
      <InfoContainer>
        <Title>{book.title}</Title>
        <Characteristics characteristics={parseCharacteristics(book.characteristics)} />
        <BookDescription>{book.description}</BookDescription>
        <PriceElement itemId={book.id} text="Libro impreso" price={book.price} item={book} />
        {book.digitalId && (
          <PriceElement
            itemId={book.digitalId}
            text="Libro digital"
            price={book.digitalPrice}
            item={book}
            digital
          />
        )}
      </InfoContainer>
    </ContentWrapper>
    <div className="bottom-separator" />
  </Wrapper>
);

const Wrapper = styled.div`
  border-top: 2px solid ${colors.white};

  .bottom-separator {
    width: 100%;
    height: 11px;
    background-color: ${colors.mainOrange};
  }

  &:last-child {
    .bottom-separator {
      display: none;
    }
  }
`;

const ContentWrapper = styled.div`
  @media (min-width: ${breakpoints.md}px) {
    display: flex;
    border-bottom: 2px solid ${colors.white};
  }
`;

const CategoryBanner = styled.div`
  display: flex;
  position: absolute;
  z-index: 2;
`;

const CategoryBannerText = styled.div`
  background-color: ${colors.mainBlue};
  height: 30px;
  font-family: 'RobotoSlabBold';
  color: ${colors.white};
  font-size: 12px;
  padding: 5px 23px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  text-transform: uppercase;
`;

const CategoryBannerTail = styled.img`
  height: 30px;
`;

const Images = styled.div`
  position: relative;

  .custom-slick-dots {
    position: absolute;
    right: 12px;
    bottom: 12px;
    list-style-type: none;
    margin: 0;
    padding: 0;
    justify-content: flex-end;
    display: flex !important;

    .custom-dot {
      background-image: url(${EmptySliderDot});
      width: 17px;
      height: 17px;
    }

    .slick-active {
      .custom-dot {
        background-image: url(${FullSliderDot});
      }
    }
  }

  @media (min-width: ${breakpoints.md}px) {
    width: 400px;
    height: 400px;
  }
`;

const SliderImage = styled.img`
  width: 100%;
  margin: 0 auto;
`;

const PrevArrowImage = styled.img`
  position: absolute;
  top: 5px;
  right: 40px;
  padding: 5px;
  z-index: 2;
  cursor: pointer;
`;

const NextArrowImage = styled.img`
  transform: rotate(180deg);
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px;
  z-index: 2;
  cursor: pointer;
`;

const InfoContainer = styled.div`
  padding: 45px 50px 50px;
  color: ${colors.mainBlue};

  @media (min-width: ${breakpoints.md}px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 40px;
  }

  @media (min-width: ${breakpoints.lg}px) {
    padding: 0 50px 0;
  }
`;

const Title = styled.h1`
  font-family: 'RobotoBold';
  font-size: 24px;
  margin: 0 0 8px;
  text-transform: uppercase;

  @media (min-width: ${breakpoints.lg}px) {
    font-size: 36px;
  }
`;

const BookDescription = styled.div`
  font-family: 'RobotoSlabLight';
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 20px;

  @media (min-width: ${breakpoints.md}px) {
    width: 320px;
  }

  @media (min-width: ${breakpoints.lg}px) {
    font-size: 14px;
    width: 560px;
  }
`;

BookInfo.propTypes = {
  book: PropTypes.shape({
    category: PropTypes.string,
    images: PropTypes.arrayOf(PropTypes.shape({})),
    title: PropTypes.string,
    characteristics: PropTypes.arrayOf(PropTypes.shape({})),
    description: PropTypes.string,
    id: PropTypes.string,
    digitalId: PropTypes.string,
    price: PropTypes.number,
    digitalPrice: PropTypes.number,
  }),
};

BookInfo.defaultProps = {
  book: {},
};

export default BookInfo;
