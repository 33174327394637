import { createClient } from 'contentful-management';

const client = createClient({
  accessToken: process.env.REACT_APP_MANAGEMENT_ACCESS_TOKEN,
});

export const createComment = (values) =>
  client
    .getSpace('flwtcjtcbjfz')
    .then((space) => space.getEnvironment('master'))
    .then((environment) =>
      environment.createEntry('comment', {
        fields: {
          text: {
            'en-US': values.comments,
          },
          name: {
            'en-US': values.name,
          },
          email: {
            'en-US': values.email,
          },
        },
      })
    )
    .then(() => 'success')
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error(error);
      return 'error';
    });

const createProduct = async (items) => {
  const productsIds = [];
  const promisesArray = [];
  items.forEach((item) => {
    const promise = client
      .getSpace('flwtcjtcbjfz')
      .then((space) => space.getEnvironment('master'))
      .then((environment) =>
        environment.createEntry('product', {
          fields: {
            title: {
              'en-US': item.title,
            },
            quantity: {
              'en-US': item.quantity,
            },
            price: {
              'en-US': item.price,
            },
            total: {
              'en-US': item.total,
            },
            product: {
              'en-US': {
                sys: {
                  id: item.id,
                  type: 'Link',
                  linkType: 'Entry',
                },
              },
            },
          },
        })
      )
      .then((entry) => productsIds.push(entry.sys.id));
    promisesArray.push(promise);
  });
  return Promise.all(promisesArray).then(() => productsIds);
};

export const createPurchase = async (purchase) => {
  const productsIds = await createProduct(purchase.items);
  const products = productsIds.map((productId) => {
    const productLink = {
      sys: {
        id: productId,
        type: 'Link',
        linkType: 'Entry',
      },
    };
    return productLink;
  });
  return client
    .getSpace('flwtcjtcbjfz')
    .then((space) => space.getEnvironment('master'))
    .then((environment) =>
      environment.createEntry('purchase', {
        fields: {
          city: {
            'en-US': purchase.city,
          },
          paymentMethod: {
            'en-US': purchase.paymentMethod,
          },
          country: {
            'en-US': purchase.country,
          },
          province: {
            'en-US': purchase.province,
          },
          shippingMethod: {
            'en-US': purchase.shippingMethod,
          },
          total: {
            'en-US': purchase.total,
          },
          address: {
            'en-US': purchase.address,
          },
          comments: {
            'en-US': purchase.comments,
          },
          document: {
            'en-US': purchase.document,
          },
          email: {
            'en-US': purchase.email,
          },
          lastName: {
            'en-US': purchase.lastName,
          },
          name: {
            'en-US': purchase.name,
          },
          phone: {
            'en-US': purchase.phone,
          },
          withInvoice: {
            'en-US': purchase.withInvoice,
          },
          products: {
            'en-US': products,
          },
        },
      })
    )
    .then(() => 'success')
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error(error);
      return 'error';
    });
};
