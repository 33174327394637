import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import { Map, Marker } from 'pigeon-maps';
import Layout from '../../components/Layout';
import BulletIcon from '../../assets/images/bullet-icon.svg';
import LocationIcon from '../../assets/images/location-icon.svg';
import WebIcon from '../../assets/images/web-icon.svg';
import GET_DISTRIBUTION_POINTS from './queries';
import { colors, breakpoints } from '../../config/constants';

const defaultMapCoords = [-1.260986467062105, -78.653644604832];

const parsePoints = (points) => {
  const cityObject = {};
  if (points) {
    points.distributionPointCollection.items.forEach((point) => {
      const parsedPoint = {
        id: point.sys.id,
        name: point.name,
        webPage: point.webPage,
        webPageLink: point.webPageLink,
        addresses: point.addressesCollection.items,
        order: point.city.order,
      };
      const lowerCity = point.city.name.toLowerCase();
      if (!cityObject[lowerCity]) {
        const pointsArray = [];
        pointsArray.push(parsedPoint);
        cityObject[lowerCity] = pointsArray;
      } else {
        cityObject[lowerCity].push(parsedPoint);
      }
    });
  }
  Object.keys(cityObject).forEach((city) => {
    cityObject[city].sort((a, b) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  });
  return cityObject;
};

const DistributionPointsPage = () => {
  const { data: distributionPoints } = useQuery(GET_DISTRIBUTION_POINTS);
  const [parsedPoints, setParsedPoints] = useState();
  const [selectedAddress, setSelectedAddress] = useState();
  const [selectedAddressId, setSelectedAddressId] = useState();

  const orderPointsByCity = (points) => {
    points.sort((a, b) => parsedPoints[a][0].order - parsedPoints[b][0].order);
    return points;
  };

  const handleAddressClick = (address) => {
    setSelectedAddress([parseFloat(address.longitude), parseFloat(address.latitude)]);
    setSelectedAddressId(address.sys.id);
  };

  useEffect(() => {
    if (distributionPoints) {
      setParsedPoints(parsePoints(distributionPoints));
    }
  }, [distributionPoints]);

  useEffect(() => {
    document.title = 'Eufonía - Puntos de distribución'; // eslint-disable-line
  }, []);

  return (
    <Layout>
      <MapWrapper>
        <Map
          defaultCenter={defaultMapCoords}
          center={selectedAddress}
          zoom={selectedAddress ? 17 : 7}
        >
          {selectedAddress && (
            <Marker width={40} anchor={selectedAddress} color={colors.mainBlue} />
          )}
        </Map>
        <div className="bottom-separator" />
      </MapWrapper>
      <Wrapper>
        {parsedPoints &&
          orderPointsByCity(Object.keys(parsedPoints)).map((city) => (
            <CityWrapper key={`points-${city}`}>
              <City>{city}</City>
              {parsedPoints[city].map((point) => (
                <PoinWrapper key={point.id}>
                  <Point>
                    <Bullet src={BulletIcon} alt="" />
                    <Name>{point.name}</Name>
                  </Point>
                  {point.addresses.map((address) => (
                    <Point key={address.sys.id}>
                      <BulletLocation src={LocationIcon} alt="" />
                      <Address
                        onClick={() => handleAddressClick(address)}
                        active={address.sys.id === selectedAddressId}
                      >
                        {address.name}
                      </Address>
                    </Point>
                  ))}
                  {point.webPage && (
                    <Point>
                      <BulletLink src={WebIcon} alt="" />
                      <Link href={point.webPageLink} target="_blank">
                        {point.webPage}
                      </Link>
                    </Point>
                  )}
                </PoinWrapper>
              ))}
            </CityWrapper>
          ))}
      </Wrapper>
    </Layout>
  );
};

const MapWrapper = styled.div`
  border-top: 2px solid ${colors.white};
  position: sticky;
  top: 0;
  height: 220px;

  .bottom-separator {
    width: 100%;
    height: 11px;
    background-color: ${colors.mainOrange};
    margin-top: -3px;
  }

  @media (min-width: ${breakpoints.md}px) {
    height: 350px;
  }
`;

const Wrapper = styled.div`
  overflow: hidden;
  padding: 45px 50px;

  @media (min-width: ${breakpoints.md}px) {
    padding: 45px 100px;
  }

  @media (min-width: ${breakpoints.lg}px) {
    padding: 45px 300px;
  }
`;

const CityWrapper = styled.div`
  margin-bottom: 20px;
`;

const City = styled.h2`
  color: ${colors.mainBlue};
  font-family: 'RobotoBold';
  font-size: 24px;
  margin: 0;
  margin-bottom: 10px;
  text-transform: uppercase;

  @media (min-width: ${breakpoints.md}px) {
    font-size: 36px;
  }
`;

const Point = styled.div`
  display: flex;
  align-items: center;
`;

const PoinWrapper = styled.div`
  margin-bottom: 15px;
`;

const Bullet = styled.img`
  margin-right: 5px;
  height: 10px;
`;

const BulletLocation = styled(Bullet)`
  height: 13px;
`;

const BulletLink = styled(Bullet)`
  padding-top: 4px;
  height: 13px;
`;

const Name = styled.p`
  margin: 0;
  color: ${colors.mainBlue};
  font-family: 'RobotoSlabRegular';
  font-size: 12px;
  font-weight: bold;

  @media (min-width: ${breakpoints.md}px) {
    font-size: 14px;
  }
`;

const Address = styled.p`
  margin: 2px 0;
  color: ${colors.mainBlue};
  font-family: 'RobotoSlabLight';
  font-size: 12px;
  cursor: pointer;
  text-decoration: ${(props) => (props.active ? 'underline' : 'none')};

  :hover {
    text-decoration: underline;
  }

  @media (min-width: ${breakpoints.md}px) {
    font-size: 14px;
  }
`;

const Link = styled.a`
  color: ${colors.mainBlue};
  font-family: 'RobotoSlabBold';
  font-size: 12px;

  @media (min-width: ${breakpoints.md}px) {
    font-size: 14px;
  }
`;

export default DistributionPointsPage;
