import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import BulletIcon from '../../assets/images/bullet-icon.svg';
import { colors, breakpoints } from '../../config/constants';

const Characteristics = ({ className, characteristics }) => (
  <CharacteristicsWrapper className={className}>
    {characteristics.map((characteristic, index) => (
      <CharacteristicWrapper key={`characteristic-${index + 1}`}>
        <Bullet src={BulletIcon} alt="" />
        <Characteristic>{`${characteristic.title}:`}</Characteristic>
        <CharacteristicInfo>{characteristic.value}</CharacteristicInfo>
      </CharacteristicWrapper>
    ))}
  </CharacteristicsWrapper>
);

const CharacteristicsWrapper = styled.div`
  margin-bottom: 12px;
  color: ${colors.mainBlue};
`;

const CharacteristicWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
`;

const Bullet = styled.img`
  width: 11px;
  margin-right: 2px;

  @media (min-width: ${breakpoints.md}px) {
    width: 12px;
  }
`;

const Characteristic = styled.div`
  font-family: 'RobotoSlabBold';
  margin-right: 4px;

  @media (min-width: ${breakpoints.lg}px) {
    font-size: 14px;
  }
`;

const CharacteristicInfo = styled.div`
  font-family: 'RobotoSlabLight';

  @media (min-width: ${breakpoints.lg}px) {
    font-size: 14px;
  }
`;

Characteristics.propTypes = {
  className: PropTypes.string,
  characteristics: PropTypes.arrayOf(PropTypes.shape({})),
};

Characteristics.defaultProps = {
  className: '',
  characteristics: [],
};

export default Characteristics;
