import { gql } from '@apollo/client';

const GET_GALLERY = gql`
  query GetGallery($id: String) {
    galleryItemCollection(where: { gallery: { sys: { id: $id } } }, order: [title_ASC]) {
      items {
        sys {
          id
        }
        image {
          url
        }
        title
        author
        technique
        specialImage
        characteristicsCollection(limit: 10) {
          items {
            title {
              title
            }
            value {
              value
            }
          }
        }
        galleryItemVariationCollection(limit: 10) {
          items {
            sys {
              id
            }
            name
            dimension
            price
            hasFrame
          }
        }
      }
    }
  }
`;

export default GET_GALLERY;
