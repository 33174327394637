import React from 'react';
import { useStoreRehydrated } from 'easy-peasy';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import Analytics from 'react-router-ga';
import apolloClient from './config/apollo/apolloClient';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import AuthorsPage from './pages/AuthorsPage';
import IllustratorsPage from './pages/IllustratorsPage';
import GalleriesPage from './pages/GalleriesPage';
import GalleryPage from './pages/GalleryPage';
import EnjambrePage from './pages/EnjambrePage';
import DistributionPointsPage from './pages/DistributionPointsPage';
import ContactPage from './pages/ContactPage';
import ShoppingCartPage from './pages/ShoppingCartPage';

function App() {
  const isRehydrated = useStoreRehydrated();
  return (
    isRehydrated && (
      <ApolloProvider client={apolloClient}>
        <Router>
          <Analytics id={process.env.REACT_APP_GA_ID}>
            <Switch>
              <Route exact path="/">
                <HomePage />
              </Route>
              <Route path="/sobre-nosotros">
                <AboutPage />
              </Route>
              <Route path="/autores">
                <AuthorsPage />
              </Route>
              <Route path="/ilustradores">
                <IllustratorsPage />
              </Route>
              <Route path="/puntos-de-distribucion">
                <DistributionPointsPage />
              </Route>
              <Route path="/galeria-de-arte">
                <GalleriesPage />
              </Route>
              <Route path="/galeria/:id">
                <GalleryPage />
              </Route>
              <Route path="/enjambre">
                <EnjambrePage />
              </Route>
              <Route path="/contactanos">
                <ContactPage />
              </Route>
              <Route path="/carrito-de-compras">
                <ShoppingCartPage />
              </Route>
              <Route path="*">
                <Redirect to="/" />
              </Route>
            </Switch>
          </Analytics>
        </Router>
      </ApolloProvider>
    )
  );
}

export default App;
