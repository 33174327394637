import { gql } from '@apollo/client';

const GET_DISTRIBUTION_POINTS = gql`
  query GetDistributionPoints {
    distributionPointCollection {
      items {
        sys {
          id
        }
        city {
          name
          order
        }
        name
        webPage
        webPageLink
        addressesCollection {
          items {
            sys {
              id
            }
            name
            longitude
            latitude
          }
        }
      }
    }
  }
`;

export default GET_DISTRIBUTION_POINTS;
