import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import Layout from '../../components/Layout';
import BookInfo from '../../components/BookInfo';
import GET_BOOKS from './queries';

const parseBooks = (books) => {
  const parsedData = books.bookCollection.items.map((book) => {
    const parsedBook = {
      id: book.sys.id,
      title: book.title,
      author: book.author,
      description: book.description,
      price: book.price,
      digitalId: book.digital?.sys.id,
      digitalPrice: book.digital?.price,
      category: book.category.name,
      characteristics: book.characteristicsCollection.items,
      images: book.imagesCollection.items,
    };
    return parsedBook;
  });
  return parsedData;
};

const HomePage = () => {
  const { data: books } = useQuery(GET_BOOKS);

  useEffect(() => {
    document.title = 'Eufonía - Libros'; // eslint-disable-line
  }, []);

  return (
    <Layout>
      {books && parseBooks(books).map((book) => <BookInfo key={book.id} book={book} />)}
    </Layout>
  );
};

export default HomePage;
