import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import Layout from '../../components/Layout';
import GalleryItem from '../../components/GalleryItem';
import GET_GALLERY from './queries';
import { breakpoints } from '../../config/constants';

const parseCharacteristics = (characteristics) => {
  const parsedCharacteristics = characteristics.map((characteristic) => ({
    title: characteristic.title.title,
    value: characteristic.value.value,
  }));
  return parsedCharacteristics;
};

const parseGallery = (gallery) => {
  if (gallery) {
    const parsedData = gallery.galleryItemCollection.items.map((galleryItem) => {
      const parsedGallery = {
        ...galleryItem,
        id: galleryItem.sys.id,
        imageUrl: galleryItem.image.url,
        characteristics: parseCharacteristics(galleryItem.characteristicsCollection.items),
        products: galleryItem.galleryItemVariationCollection.items,
      };
      return parsedGallery;
    });
    return parsedData;
  }
  return false;
};

const GalleryPage = () => {
  const { id } = useParams();
  const { data: gallery } = useQuery(GET_GALLERY, { variables: { id } });
  const [currentGalleryItem, setCurrentGalleryItem] = useState();

  const handleImageClick = (galleryItem) => {
    setCurrentGalleryItem(galleryItem);
  };

  useEffect(() => {
    document.title = 'Eufonía - Galería de arte'; // eslint-disable-line
  }, []);

  return (
    <>
      <Layout>
        <Wrapper>
          {gallery &&
            parseGallery(gallery).map((galleryItem) => (
              <GalleryItem
                key={galleryItem.id}
                galleryItem={galleryItem}
                onImageClick={handleImageClick}
              />
            ))}
        </Wrapper>
      </Layout>
      {currentGalleryItem && (
        <ImageOverlay onClick={() => setCurrentGalleryItem(null)}>
          <ImageContainer image={currentGalleryItem.imageUrl} />
        </ImageOverlay>
      )}
    </>
  );
};

const Wrapper = styled.div`
  @media (min-width: ${breakpoints.lg}px) {
    margin-bottom: 74px;
  }
`;

const ImageOverlay = styled.div`
  width: 100vw;
  height: 100vh;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  background-image: ${(props) => `url(${props.image})` || ''};
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;

  @media (min-width: ${breakpoints.lg}px) {
    width: 90%;
    height: 90%;
  }
`;

export default GalleryPage;
