import React, { useEffect } from 'react';
import styled from 'styled-components';
import Layout from '../../components/Layout';
import EnjambreMain from '../../assets/images/enjambre-main.jpg';
import EnjambreMainAlt from '../../assets/images/enjambre-main-alt.jpg';
import BulletIcon from '../../assets/images/bullet-icon.svg';
import { colors, breakpoints } from '../../config/constants';

const EnjambrePage = () => {
  useEffect(() => {
    document.title = 'Eufonía - Enjambre'; // eslint-disable-line
  }, []);

  return (
    <Layout>
      <Wrapper>
        <Banner src={EnjambreMain} alt="" />
        <SectionWrapper>
          <AltBanner src={EnjambreMainAlt} alt="" />
          <Section>
            <Box>
              <h2>¿QUIÉNES SOMOS?</h2>
              <p>
                <Italic>ENJAMBRE, Red de librerías y editoriales independientes de Quito</Italic>{' '}
                somos una plataforma que se agrupó a partir de la emergencia sanitaria y la
                integramos diferentes agentes del medio editorial y librero local.
              </p>
              <p>
                Nuestro principal objetivo es estimular prácticas colectivas y solidarias de
                trabajo.
              </p>
              <p>Actualmente, los miembros de ENJAMBRE somos:</p>
              <WithBulletMargin>
                <Bullet src={BulletIcon} alt="" />
                <p>
                  <Bold>Editoriales: </Bold>
                  <a
                    href="https://www.facebook.com/Doble-Rostro-210702838947805"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Doble Rostro
                  </a>
                  , Eufonía,{' '}
                  <a
                    href="http://editorialfestinalente.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Festina lente
                  </a>
                  ,{' '}
                  <a
                    href="https://www.facebook.com/severoeditorial"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Severo
                  </a>
                  .
                </p>
              </WithBulletMargin>
              <WithBulletMargin>
                <Bullet src={BulletIcon} alt="" />
                <p>
                  <Bold>Librerías: </Bold>
                  <a href="https://elosolector.com/" target="_blank" rel="noopener noreferrer">
                    El oso lector
                  </a>
                  ,{' '}
                  <a href="https://tolstoilibreria.com/" target="_blank" rel="noopener noreferrer">
                    Tolstói
                  </a>
                  .
                </p>
              </WithBulletMargin>
            </Box>
          </Section>
        </SectionWrapper>
        <SectionWrapper>
          <AltTitle>
            <div>
              <h2>¿QUÉ HACEMOS?</h2>
            </div>
          </AltTitle>
          <SpecialSection>
            <Box>
              <h2>¿QUÉ HACEMOS?</h2>
              <WithBullet>
                <Bullet src={BulletIcon} alt="" />
                <p>Difundir colectivamente nuestros catálogos.</p>
              </WithBullet>
              <WithBullet>
                <Bullet src={BulletIcon} alt="" />
                <p>Promover la compra directa de libros a editoriales y librerías.</p>
              </WithBullet>
              <WithBullet>
                <Bullet src={BulletIcon} alt="" />
                <p>Articular estrategias compartidas que estimulen la lectura.</p>
              </WithBullet>
              <WithBullet>
                <Bullet src={BulletIcon} alt="" />
                <p>Programar actividades de lectura, diálogo y asesoría en línea.</p>
              </WithBullet>
              <WithBullet>
                <Bullet src={BulletIcon} alt="" />
                <p>Donar libros.</p>
              </WithBullet>
            </Box>
          </SpecialSection>
        </SectionWrapper>
        <SectionWrapper>
          <AltTitle>
            <div>
              <h2>¿CÓMO PUEDES AYUDAR?</h2>
            </div>
          </AltTitle>
          <SpecialSection>
            <Box>
              <h2>¿CÓMO PUEDES AYUDAR?</h2>
              <WithBullet>
                <Bullet src={BulletIcon} alt="" />
                <p>
                  <Bold>Donaciones económicas: </Bold>tus aportes servirán para crear una plataforma
                  en línea que aglutine los catálogos y actividades de la Red.
                </p>
              </WithBullet>
              <WithBullet>
                <Bullet src={BulletIcon} alt="" />
                <p>
                  <Bold>Donaciones de libros: </Bold>para abastecer a las librerías de segunda
                </p>
              </WithBullet>
              <WithBullet>
                <Bullet src={BulletIcon} alt="" />
                <p>
                  <Bold>Trueque: </Bold>te contaremos qué necesidades tenemos y cómo las podemos
                  canjear.
                </p>
              </WithBullet>
            </Box>
          </SpecialSection>
        </SectionWrapper>
        <SectionWrapper>
          <AltTitle>
            <div>
              <h2>¿QUIERES SER PARTE DE LA RED?</h2>
            </div>
          </AltTitle>
          <SpecialSection>
            <Box>
              <h2>¿QUIERES SER PARTE DE LA RED?</h2>
              <p>
                Si tienes una librería o editorial independiente y quieres sumarte a nuestras
                iniciativas, o si quieres apoyarnos económicamente o mediante donaciones, escríbenos
                a nuestro correo:
              </p>
              <LinkText>enjambrelared@yahoo.com</LinkText>
            </Box>
          </SpecialSection>
        </SectionWrapper>
      </Wrapper>
    </Layout>
  );
};

const Wrapper = styled.div`
  border-top: 2px solid ${colors.white};
`;

const SectionWrapper = styled.div`
  display: flex;
`;

const AltTitle = styled.div`
  display: none;

  @media (min-width: ${breakpoints.md}px) {
    display: flex;
    border-bottom: 2px solid ${colors.mainBlue};

    div {
      display: flex;
      background-color: ${colors.textBackground};
      width: 400px;
      height: 175px;
      justify-content: center;
      align-items: center;
      margin-top: 5px;
      margin-bottom: 5px;
      padding: 0px 70px;
      box-sizing: border-box;
      text-align: center;
    }

    h2 {
      color: ${colors.mainBlue};
      font-family: 'RobotoBold';
      font-size: 24px;
      margin: 0;
    }
  }

  @media (min-width: ${breakpoints.lg}px) {
    div {
      padding: 0px 30px;
    }

    h2 {
      font-size: 36px;
    }
  }
`;

const Banner = styled.img`
  width: 100%;

  @media (min-width: ${breakpoints.md}px) {
    display: none;
  }
`;

const AltBanner = styled.img`
  display: none;

  @media (min-width: ${breakpoints.md}px) {
    display: block;
    padding-bottom: 5px;
    border-bottom: 2px solid ${colors.mainBlue};
  }
`;

const Box = styled.div`
  @media (min-width: ${breakpoints.md}px) {
    width: 288px;
  }

  @media (min-width: ${breakpoints.lg}px) {
    width: 500px;
  }
`;

const Section = styled.div`
  padding: 45px 50px;
  border-bottom: 2px solid ${colors.mainBlue};
  width: 100%;

  h2 {
    font-family: 'RobotoBold';
    color: ${colors.mainBlue};
    margin: 0 0 10px;

    @media (min-width: ${breakpoints.lg}px) {
      font-size: 36px;
    }
  }

  p {
    color: ${colors.mainBlue};
    font-family: 'RobotoSlabLight';
    font-size: 12px;
    margin: 0;
    margin-bottom: 11px;
    line-height: 14px;
  }

  @media (min-width: ${breakpoints.md}px) {
    flex: 1;
    padding: 0 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  @media (min-width: ${breakpoints.lg}px) {
    p {
      font-size: 14px;
      margin-bottom: 17px;
      line-height: normal;
    }
  }
`;

const WithBullet = styled.div`
  display: flex;

  p {
    margin: 0;
  }
`;

const WithBulletMargin = styled.div`
  display: flex;
  margin-bottom: 11px;

  p {
    margin: 0;
  }

  a {
    color: ${colors.mainBlue};
  }
`;

const SpecialSection = styled(Section)`
  p {
    margin: 0;
  }

  @media (min-width: ${breakpoints.md}px) {
    h2 {
      display: none;
    }
  }
`;

const Bullet = styled.img`
  margin-right: 2px;
  height: 10px;
  margin-top: 3px;

  @media (min-width: ${breakpoints.lg}px) {
    margin-top: 5px;
  }
`;

const Italic = styled.span`
  font-style: italic;
`;

const Bold = styled.span`
  font-family: 'RobotoSlabBold';
`;

const LinkText = styled.p`
  font-family: 'RobotoSlabBold' !important;
  text-decoration: underline;
  margin-top: 12px !important;
`;

export default EnjambrePage;
