import { gql } from '@apollo/client';

const GET_GALLERIES = gql`
  query GetGalleries {
    galleryCollection(order: [year_DESC]) {
      items {
        sys {
          id
        }
        image {
          url
        }
        title
        artist
        year
      }
    }
  }
`;

export default GET_GALLERIES;
