import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import Layout from '../../components/Layout';
import GET_GALLERIES from './queries';
import { colors, breakpoints } from '../../config/constants';

const parseGallery = (gallery) => {
  if (gallery) {
    const parsedData = gallery.galleryCollection.items.map((galleryItem) => {
      const parsedGallery = {
        ...galleryItem,
        id: galleryItem.sys.id,
        imageUrl: galleryItem.image.url,
      };
      return parsedGallery;
    });
    return parsedData;
  }
  return false;
};

const GalleryPage = () => {
  const history = useHistory();
  const { data: galleries } = useQuery(GET_GALLERIES);

  const handleImageClick = (galleryItem) => {
    history.push(`galeria/${galleryItem.id}`);
  };

  useEffect(() => {
    document.title = 'Eufonía - Galería de arte'; // eslint-disable-line
  }, []);

  return (
    <Layout special>
      <Wrapper>
        {galleries &&
          parseGallery(galleries).map((galleryItem) => (
            <ImageWrapper key={galleryItem.id}>
              <ImageButton onClick={() => handleImageClick(galleryItem)}>
                <GalleryItem background={galleryItem.imageUrl}>
                  <GalleryItemTitle>{galleryItem.title}</GalleryItemTitle>
                  <GalleryItemRow>
                    <span>Artista:</span>
                    {` ${galleryItem.artist}`}
                  </GalleryItemRow>
                  <GalleryItemRow>
                    <span>Año:</span>
                    {` ${galleryItem.year}`}
                  </GalleryItemRow>
                </GalleryItem>
              </ImageButton>
            </ImageWrapper>
          ))}
      </Wrapper>
    </Layout>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const ImageWrapper = styled.div`
  margin: 0 -2px;
  width: 100%;
  margin: 2px;

  @media (min-width: ${breakpoints.sm}px) {
    width: calc(50% - 4px);
  }

  @media (min-width: ${breakpoints.md}px) {
    width: calc(33.33% - 4px);
  }

  @media (min-width: ${breakpoints.lg}px) {
    width: calc(25% - 4px);
  }
`;

const ImageButton = styled.button`
  border: 0;
  padding: 0;
  width: 100%;
  display: flex;
  cursor: pointer;
  background-color: transparent;
  padding-top: 100%;
  position: relative;
`;

const GalleryItem = styled.div`
  background-image: ${(props) => `url(${props.background})` || ''};
  width: 100%;
  background-size: cover;
  background-color: ${colors.mainBlue};
  color: ${colors.white};
  text-align: left;
  padding: 0 25px 22px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  box-sizing: border-box;

  p {
    margin: 0;
    margin-bottom: 2px;
    font-size: 12px;

    @media (min-width: ${breakpoints.lg}px) {
      font-size: 14px;
    }
  }
`;

const GalleryItemTitle = styled.p`
  font-family: 'RobotoBold';
  text-transform: uppercase;
`;

const GalleryItemRow = styled.p`
  font-family: 'RobotoSlabLight';

  span {
    font-family: 'RobotoSlabBold';
  }
`;

export default GalleryPage;
