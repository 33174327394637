import { action } from 'easy-peasy';

const shoppingCartModel = {
  shoppingCart: [],
  addToCart: action((state, item) => {
    const itemIndex = state.shoppingCart.findIndex(
      (shoppingCartItem) => shoppingCartItem.id === item.id
    );
    if (itemIndex >= 0) {
      const newQuantity = state.shoppingCart[itemIndex].quantity + 1;
      state.shoppingCart[itemIndex].quantity = newQuantity; // eslint-disable-line
      state.shoppingCart[itemIndex].total = newQuantity * item.price; // eslint-disable-line
    } else {
      state.shoppingCart.push({
        id: item.id,
        quantity: 1,
        price: item.price,
        title: item.title,
        author: item.author,
        isDigital: item.isDigital,
        total: item.price,
      });
    }
  }),
  removeFromCart: action((state, item) => {
    const itemIndex = state.shoppingCart.findIndex(
      (shoppingCartItem) => shoppingCartItem.id === item.id
    );
    if (itemIndex >= 0) {
      if (state.shoppingCart[itemIndex].quantity > 1) {
        const newQuantity = state.shoppingCart[itemIndex].quantity - 1;
        state.shoppingCart[itemIndex].quantity = newQuantity; // eslint-disable-line
        state.shoppingCart[itemIndex].total = newQuantity * item.price; // eslint-disable-line
      } else if (state.shoppingCart[itemIndex].quantity === 1) {
        state.shoppingCart.splice(itemIndex, 1);
      }
    }
  }),
  emptyCart: action((state) => {
    state.shoppingCart = []; // eslint-disable-line
  }),
};

export default shoppingCartModel;
