import React, { useEffect } from 'react';
import styled from 'styled-components';
import Layout from '../../components/Layout';
import AboutImageMobile from '../../assets/images/about-image-mobile.jpg';
import AboutImageDesktop from '../../assets/images/about-image-desktop.jpg';
import { colors, breakpoints } from '../../config/constants';

const AboutPage = () => {
  useEffect(() => {
    document.title = 'Eufonía - Sobre nosotros'; // eslint-disable-line
  }, []);

  return (
    <Layout special>
      <AboutWrapper>
        <ImageWrapper>
          <MobileAboutImage src={AboutImageMobile} alt="" />
          <AboutImage src={AboutImageDesktop} alt="" />
        </ImageWrapper>
        <AboutText>
          <p>
            La <SpecialText>euphonia</SpecialText> es un ave que vive en los bosques húmedos.
          </p>
          <p>
            <SpecialText>Eufonía</SpecialText> también es el sonido agradable que resulta de la
            combinación acertada de palabras.
          </p>
          <p>
            Hace un tiempo, dos euphonias volaron por Quito y nos dejaron la idea de una editorial
            que cuide sus libros como el ave cuida su canto.
          </p>
          <p>
            Así nació <SpecialText2>Eufonía ediciones</SpecialText2>, donde hacemos libros que
            &quot;suenen bien&quot;: al ojo, al tacto, al oído y al espíritu.
          </p>
        </AboutText>
      </AboutWrapper>
    </Layout>
  );
};

const AboutWrapper = styled.div`
  border-top: 2px solid ${colors.white};
  border-bottom: 2px solid ${colors.white};

  @media (min-width: ${breakpoints.md}px) {
    display: flex;
  }
`;

const ImageWrapper = styled.div`
  width: 100%;

  @media (min-width: ${breakpoints.md}px) {
    width: 400px;
  }

  @media (min-width: ${breakpoints.lg}px) {
    width: 480px;
  }
`;

const MobileAboutImage = styled.img`
  width: 100%;

  @media (min-width: ${breakpoints.md}px) {
    display: none;
  }
`;

const AboutImage = styled.img`
  display: none;
  width: 100%;

  @media (min-width: ${breakpoints.md}px) {
    display: block;
  }
`;

const AboutText = styled.div`
  padding: 8px 50px;
  font-family: 'RobotoSlabLight';
  color: ${colors.mainBlue};
  font-size: 12px;

  @media (min-width: ${breakpoints.md}px) {
    padding: 0 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 288px;

    p {
      margin: 0;
      margin-bottom: 11px;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }

  @media (min-width: ${breakpoints.lg}px) {
    width: 475px;
    padding: 0 50px;
    font-size: 18px;

    p {
      margin-bottom: 17px;
    }
  }
`;

const SpecialText = styled.span`
  font-family: 'RobotoRegular';
  font-weight: bold;
  font-style: italic;
`;

const SpecialText2 = styled.span`
  font-family: 'RobotoSlabRegular';
  font-weight: bold;
`;

export default AboutPage;
